<template>
  <div class="restmenuordermodalcont" v-if="product">
    <v-row justify="center">
      <v-dialog
        v-model="showdialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-alert
          v-if="error"
          class="alert-global text-body-2 white--text p-2"
          type="error"
          transition="fade-transition"
          dense
          >{{ error }}</v-alert
        >
        <v-card color="background">
          <v-toolbar dark color="toolbar">
            <router-link
              :to="{ name: 'home' }"
              class="d-flex justify-content-center align-items-center"
            >
              <v-toolbar-title>
                <v-img
                  class="mx-2"
                  :src="getMerchantLogo()"
                  max-height="40"
                  max-width="40"
                  contain
                ></v-img>
              </v-toolbar-title>
              <div class="text-uppercase">
                <small class="toolbarText--text font-weight-6">{{
                  profile.restaurant_name
                }}</small>
              </div></router-link
            >
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn class="toolbarText--text" icon dark @click="closeModal(false)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-img :src="getProductPhoto()" height="180px">
            <v-overlay absolute v-if="product.disabled === '1'">
              <v-chip color="error" class="text-body-2 font-weight-6 sold-out">Sold Out</v-chip>
            </v-overlay>
          </v-img>
          <div class="container pb-100px">
            <v-row>
              <v-col cols="8">
                <p class="fontsm font-weight-600">{{ product.item_name }}</p>
              </v-col>
              <v-col cols="4">
                <p class="fontsm-1 text-right mr-4 font-weight-600">
                  <span
                    class="text--disabled caption"
                    v-if="product.discount"
                  >
                    <strike>{{ $n(getLowestPrice()[1], 'currency', 'en-MY') }}</strike>
                  </span>
                  {{ $n((getLowestPrice()[1] - product.discount), 'currency', 'en-MY') }}
                </p>
              </v-col>
            </v-row>
            <v-row class="m-0 p-0">
              <v-col class="p-0">
                <p class="item-description font-weight-400" v-html="product.item_description"></p>
              </v-col>
            </v-row>
            <div class="sizes choices mt-4" v-if="product.skus">
              <p class="fontsm font-weight-600">Sizes</p>
              <v-container class="py-0" v-if="sizes">
                <v-container class="p-0">
                  <v-radio-group dense v-model="selectedSize" @change="onSizeChanged">
                    <v-row
                      align="center"
                      class="prods-innerextras"
                      v-for="(price, size, index) in product.skus"
                      :key="index"
                    >
                      <v-col cols="8" class="p-0">
                        <v-radio
                          small
                          class="smalltxt"
                          :label="getProductSizeName(size)"
                          :value="size"
                        ></v-radio>
                      </v-col>
                      <v-col
                        cols="4"
                        class="smalltxt pl-0 font-weight-6 text-right"
                      >
                        <div class="text--disabled caption font-weight-600" v-if="product.discount">
                          <strike>{{ $n(price, 'currency', 'en-MY') }}</strike>
                        </div>
                        {{ $n((price - product.discount), 'currency', 'en-MY') }}
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-container>
              </v-container>
            </div>

            <div v-if="addonCategories && addonItems">
              <ProductAddons
                v-for="(addonCategory) in parseAddonCategories()"
                :key="addonCategory.subcat_id"
                :category="addonCategory"
                :items="mapProductAddonItems(addonItems, parseAddonItems()[addonCategory.subcat_id])"
                :type="JSON.parse(product.multi_option)[addonCategory.subcat_id][0]"
                :required="isAddonRequired(addonCategory.subcat_id)"
                :rules="getAddonRules(addonCategory.subcat_id)"
                :disabled="!selectedSize"
              ></ProductAddons>
            </div>

            <div class="mt-6">
              <h6 class="fontsm mb-3 font-weight-600">Special Remarks:</h6>
              <div class="d-flex justify-space-between text-caption font-weight-6 mb-2" v-if="isDinein">
                <v-switch
                  v-model="isTakeaway"
                  dense
                  inset
                  label="Takeaway"
                  @change="onTakeawayChanged"
                  v-if="isMenuItemTakeawayEnabled"
                ></v-switch>
                <v-switch
                  v-model="isServeLater"
                  dense
                  inset
                  label="Serve Later"
                  @change="onServeLaterChanged"
                  v-if="isMenuItemServeLaterEnabled"
                ></v-switch>
              </div>
              <v-textarea
                v-model="specialRemarks"
                class="special-remarks"
                solo
                auto-grow
                rows="3"
                background-color="#f6f6f6"
                placeholder="Give me extra spoon"
                @blur="updateSpecialRemarks"
              ></v-textarea>
            </div>
          </div>
        </v-card>
        <v-bottom-navigation
          fixed
          :background-color="(product.disabled !== '1' ? 'primary' : 'disabled')"
          class="text-center"
        >
          <v-container class="py-1" v-if="product.disabled !== '1'">
            <v-row align="center">
              <v-col cols="4">
                <v-icon @click.stop="subQuantity()" class="toolbarText--text ml-1"
                  >mdi-minus</v-icon
                >

                <span class="toolbarText--text">{{ itemQuantity }}</span>
                <v-icon @click.stop="addQuantity()" class="toolbarText--text mr-1"
                  >mdi-plus</v-icon
                >
              </v-col>
              <v-col cols="4" class="toolbarText--text">
                <p
                  class="subtitle-1"
                  style="cursor: pointer"
                  @click.stop="addToCart()"
                >
                  Confirm
                </p>
              </v-col>
              <v-col cols="4" class="toolbarText--text">
                <p class="subtitle-1 font">{{ $n(itemTotal, 'currency', 'en-MY') }}</p>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="py-1" v-else>
            <v-row align="center">
              <v-col>
                <p
                  class="subtitle-1"
                >
                  Sold Out
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-bottom-navigation>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import ProductAddons from '../Menu/ProductAddons';

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
export default {
  props: ['product'],
  components: {
    ProductAddons,
  },
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      isTakeaway: false,
      isServeLater: false,
      specialRemarks: null,
      showdialog: true,
      isloading: false,
      quantity: 1,
      price: 0,
      discount: 0,
      selectedSize: 0,
      error: null,
      errors: {
        addons: {},
      }
    };
  },
  computed: {
    ...mapState('cart', {
      item: (state) => state.item,
      service: (state) => state.service,
    }),
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapState('product', {
      subCategories: (state) => state.addonCategories,
    }),
    ...mapGetters('cart', {
      itemQuantity: 'getItemQuantity',
      itemTotal: 'getItemTotal',
      isDinein: 'isDinein',
    }),
    ...mapGetters('menu', {
      products: 'getProducts',
    }),
    ...mapGetters('merchant', {
      isMenuItemTakeawayEnabled: 'isMenuItemTakeawayEnabled',
      isMenuItemServeLaterEnabled: 'isMenuItemServeLaterEnabled',
    }),
    ...mapGetters('product', {
      categories: 'getProductCategories',
      sizes: 'getProductSizes',
      addonCategories: 'getProductAddonCategories',
      addonItems: 'getProductAddonItems',
    }),
  },
  methods: {
    ...mapActions('menu', [
      'getProducts',
    ]),
    ...mapActions('product', [
      'getProductCategories',
      'getProductSizes',
      'getProductAddonCategories',
      'getProductAddonItems',
    ]),
    ...mapMutations('cart', [
      'ADD_TO_CART',
      'RESET_ITEM',
      'SET_ITEM_DETAILS',
      'SET_ITEM_SIZE',
      'SET_ITEM_QUANTITY',
      'SET_ITEM_PRICE',
      'SET_ITEM_TAKEAWAY',
      'SET_ITEM_SERVE_LATER',
      'SET_ITEM_SPECIAL_REMARKS',
      'SET_ITEM_SUBMIT',
    ]),
    closeModal(updated) {
      this.$emit('modal-closed', updated);
      this.reset();
      this.RESET_ITEM();
    },
    onSizeChanged() {
      this.price = this.product.skus[this.selectedSize] || 9999;
      this.SET_ITEM_SIZE({
        id: this.selectedSize,
        name: this.getProductSizeName(this.selectedSize),
      });
      this.SET_ITEM_PRICE(this.price);
    },
    onTakeawayChanged(e) {
      if (e) {
        this.isServeLater = false;
        this.SET_ITEM_SERVE_LATER(false);
      }

      this.SET_ITEM_TAKEAWAY(e);
    },
    onServeLaterChanged(e) {
      if (e) {
        this.isTakeaway = false;
        this.SET_ITEM_TAKEAWAY(false);
      }

      this.SET_ITEM_SERVE_LATER(e);
    },
    updateSpecialRemarks() {
      this.SET_ITEM_SPECIAL_REMARKS(this.specialRemarks);
    },
    validateAddToCart() {
      this.SET_ITEM_SUBMIT(true);

      if (this.product.require_addon) {
        const requiredAddon = JSON.parse(this.product.require_addon);
        const addonCategoryIds = Object.keys(requiredAddon).filter(addonCategoryId => requiredAddon[addonCategoryId]?.[0] == '2');
        for (const addonCategoryId of addonCategoryIds) {
          if (!this.mapProductAddonItems(this.addonItems, this.parseAddonItems()[addonCategoryId])?.length) {
            continue;
          }

          if (!this.item?.addons?.find(addon => addon.id == addonCategoryId)?.items[0].id) {
            return false;
          }
        }
      }

      if (this.product.multi_option) {
        const multiOption = JSON.parse(this.product.multi_option);
        const multiOptionValue = JSON.parse(this.product.multi_option_value);
        const addonCategoryIds = Object.keys(multiOption).filter(addonCategoryId => multiOption[addonCategoryId]?.[0] === 'custom');
        for (const addonCategoryId of addonCategoryIds) {
          const totalOptions = this.item?.addons?.find(addon => addon.id == addonCategoryId)?.items.length;
          if (totalOptions && !eval(totalOptions + multiOptionValue[addonCategoryId]?.[0])) {
            return false;
          }
        }
      }

      if (this.product.require_checksize) {
        const requiredChecksize = JSON.parse(this.product.require_checksize);
        const addonCategoryIds = Object.keys(requiredChecksize).filter(addonCategoryId => requiredChecksize[addonCategoryId]?.[0] !== '');
        for (const addonCategoryId of addonCategoryIds) {
          const totalQuantity = this.item?.addons?.find(addon => addon.id == addonCategoryId)?.items.reduce((a, c) => a + (c.quantity || 1), 0);
          if (totalQuantity && !eval(totalQuantity + requiredChecksize[addonCategoryId]?.[0])) {
            return false;
          }
        }
      }

      return true;
    },
    addToCart() {
      this.isloading = true;
      if (this.validateAddToCart()) {
        this.$gtag.event('add_to_cart', {
          'event_category': 'engagement',
          'event_label': 'Add Product',
          checkout_step: 1,
          'items': [{
            id: this.item.id,
            name: this.item.name,
            price: this.item.price,
            quantity: this.item.quantity,
            size: this.item.size.name,
          }],
        });

        this.updateSpecialRemarks();
        this.ADD_TO_CART(this.itemTotal);
        this.closeModal(true);
      } else {
        this.error = 'Choose your option(s) / quantity(s)';
        this.clearError();
      }
      this.isloading = false;
    },
    clearError() {
      setTimeout(() => this.error = null, 3000);
    },
    reset() {
      this.quantity = 1;
      this.price = 0;
      this.discount = 0;
      (this.specialRemarks = null),
        (this.selectedSize = this.getLowestPrice()[0]);
    },
    getProductSizeName(id) {
      return this.sizes[id].size_name;
    },
    getProductPhoto() {
      if (!this.product.photo_s3 && !this.product.photo) {
        return require(`@/assets/images/food-item-default.jpg`);
      }

      return this.product.photo_s3 ? (`${this.cdnUrl}/${this.product.photo_s3}`) : (`${this.legacyCdnUrl}/upload/${this.product.photo}`);
    },
    getMerchantLogo() {
      return this.profile.logo_s3 ? (`${this.cdnUrl}/${this.profile.logo_s3}`) : (`${this.legacyCdnUrl}/upload/${this.profile.photo}`);
    },
    getLowestPrice() {
      return Object.entries(this.product.skus).sort(
        ([, v1], [, v2]) => v1 - v2
      )[0];
    },
    prettyPrice(price) {
      return parseFloat(price).toFixed(2);
    },
    parseAddonItems() {
      return JSON.parse(this.product.addon_item || '{}');
    },
    parseAddonCategories() {
      const addonItem = JSON.parse(this.product.addon_item || '{}');
      return this.subCategories?.filter((addonCategory) => !!addonItem[addonCategory.subcat_id]);
    },
    isAddonRequired(addonCategoryId) {
      return (JSON.parse(this.product.require_addon || '{}'))[addonCategoryId]?.[0] == '2';
    },
    getAddonRules(addonCategoryId) {
      let rules = {
        customOption: '',
        quantity: '',
      };
      const multiOption = (JSON.parse(this.product.multi_option || '{}'));
      if (multiOption?.[addonCategoryId]?.[0] === 'custom') {
        const multiOptionValue = (JSON.parse(this.product.multi_option_value || '{}'));
        rules.customOption = multiOptionValue?.[addonCategoryId]?.[0];
      }

      const requireCheckSize = (JSON.parse(this.product.require_checksize || '{}'));
      if (requireCheckSize?.[addonCategoryId]?.[0]) {
        rules.quantity = requireCheckSize[addonCategoryId][0];
      }
      return rules;
    },
    addQuantity() {
      this.quantity++;
      this.SET_ITEM_QUANTITY(this.quantity);
    },
    subQuantity() {
      this.quantity = Math.max(--this.quantity, 1);
      this.SET_ITEM_QUANTITY(this.quantity);
    },
    arrayToObject(arr, id) {
      return arr.reduce((r, e) => {
        r[e[id]] = e;
        return r;
      }, {});
    },
    mapProductAddonItems(addonItems, items) {
      return items.reduce((r, e) => {
        if (addonItems[e]) {
          r.push(addonItems[e]);
        }
        return r;
      }, []);
    },
  },
  async mounted() {
    const that = this;
    window.onpopstate = function() {
      that.closeModal(false);
    };

    if (!this.categories) {
      await this.getProductCategories({
        merchantId: this.profile.merchant_id,
      });
    }

    if (!this.products) {
      await this.getProducts({
        merchantId: this.profile.merchant_id,
        service: this.service,
      });
    }

    if (!this.sizes) {
      // Get all merchant product sizes
      await this.getProductSizes({
        merchantId: this.profile.merchant_id,
      });
    }

    if (!this.addonCategories) {
      // Get all merchant product addon categories
      await this.getProductAddonCategories({
        merchantId: this.profile.merchant_id,
      });
    }

    if (!this.addonItems) {
      // Get all merchant product addon items
      await this.getProductAddonItems({
        merchantId: this.profile.merchant_id,
        service: this.service,
      });
    }
    
    this.selectedSize = this.item.size.id ?? this.getLowestPrice()[0];
    this.quantity = this.itemQuantity;
    this.specialRemarks = this.item.specialRemarks;
  }
};
</script>
<style>
.v-dialog--fullscreen > .v-card {
  height: calc(100vh - 56px);
  overflow: auto;
}
.sizes .v-input--radio-group {
  margin-top: 0px !important;
}
button:focus {
  outline: none;
}
.btnssizes .v-btn--active::before {
  opacity: 1 !important;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn--outlined {
  border-color: black !important;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group)
  .v-btn.v-btn--outlined.v-btn--active {
  border-color: white !important;
}
.btnssizes .v-btn--active .v-btn__content {
  color: white;
  font-weight: 600 !important;
}
.btnssizes .v-btn__content {
  font-weight: bold !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
label {
  margin: 0 !important;
}
.pb-100px {
  padding-bottom: 56px;
  @media screen and (max-width: 375px) .v-application .smalltxt2 : 30px;
  padding-left: 30px;
}
.fontsm {
  font-size: 18px;
}
.fontsm-1 {
  font-size: 15px;
}
.alert-global {
  position: fixed;
  z-index: 999;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
}
@media screen and (max-width: 375px) {
  .v-application .smalltxt2 {
    font-size: 13.5px !important;
  }
}
@media screen and (max-width: 360px) {
  .v-application .smalltxt2 {
    font-size: 12.8px !important;
  }
}
@media screen and (max-width: 320px) {
  .v-application .smalltxt2 {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 414px) {
  .quantxt {
    font-size: 12px;
  }
  .fontsm {
    font-size: 16px;
  }
  .fontsm-1 {
    font-size: 14px;
  }
  .prods-innerextras small.smalltxt {
    font-size: 10px !important;
  }
  .prods-innerextras .smalltxt,
  .prods-innerextras .v-label, 
  .item-description {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  .special-remarks {
    font-size: 12px !important;
    font-style: italic;
  }
}
@media screen and (min-width: 1024px) {
  .quantxt {
    font-size: 12px;
  }
  .fontsm {
    font-size: 16px;
  }
  .fontsm-1 {
    font-size: 14px;
  }
  .prods-innerextras small.smalltxt {
    font-size: 10px !important;
  }
  .prods-innerextras .smalltxt,
  .prods-innerextras .v-label, 
  .item-description {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  .special-remarks {
    font-size: 12px !important;
    font-style: italic;
  }
  .alert-global {
    width: 374px;
  }
}
</style>
