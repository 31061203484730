<template>
  <v-container class="p-0">
    <v-row
      align="center"
      class="prods-innerextras p-0"
      v-for="(item, i) in list"
      :key="i"
    >
      <v-col cols="7" align-self="center" class="p-0">
        <v-checkbox
          class="smalltxt"
          dense
          hide-details
          v-model="selected"
          :label="item.sub_item_name"
          :value="item.sub_item_id"
          :disabled="disabled"
          :error="required && addonItem.submit && !isRulesPassed()"
          @change="toggleAddon(item)"
        ></v-checkbox>
      </v-col>
      <v-col cols="2" align-self="center" class="extrasquanbtns p-0 text-right">
        <template v-if="item.quantity == 2">
          <div>
            <v-icon
              small
              color="primary"
              class="mr-1 quanicon"
              @click.stop="subQuantity(item.sub_item_id)"
              :disabled="disabled || !selected.includes(item.sub_item_id)"
              >mdi-minus</v-icon
            >
            <span class="quantxt">{{
              getQuantity(item.sub_item_id)
            }}</span>
            <v-icon
              small
              color="primary"
              class="ml-1 quanicon"
              @click.stop="addQuantity(item.sub_item_id)"
              :disabled="disabled || !selected.includes(item.sub_item_id)"
              >mdi-plus</v-icon
            >
          </div>
        </template>
      </v-col>
      <v-col cols="3" align-self="center" class="v-label pt-2 pl-0 font-weight-6 text-right">
        +{{ $n(getPrice(item.sub_item_id), 'currency', 'en-MY') }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
export default {
  name: 'ProductAddonMultiple',
  props: ['category', 'categoryName', 'list', 'required', 'rules', 'disabled'],
  data() {
    return {
      quantity: {},
      price: {},
      selected: [],
    };
  },
  computed: {
    ...mapState('cart', {
      addonItem: (state) => state.item,
    }),
  },
  methods: {
    ...mapMutations('cart', [
      'ADD_ITEM_ADDON',
      'UPDATE_ITEM_ADDON',
      'REMOVE_ITEM_ADDON',
    ]),
    addQuantity(id) {
      this.quantity[id]++;

      this.UPDATE_ITEM_ADDON({
        key: this.category,
        keyName: this.categoryName,
        id: id,
        multiple: true,
        quantity: this.quantity[id],
        price: this.price[id],
      });
    },
    subQuantity(id) {
      this.quantity[id] = Math.max(--this.quantity[id], 1);

      this.UPDATE_ITEM_ADDON({
        key: this.category,
        keyName: this.categoryName,
        id: id,
        multiple: true,
        quantity: this.quantity[id],
        price: this.price[id],
      });
    },
    toggleAddon(item) {
      if (this.selected.includes(item.sub_item_id)) {
        if (this.quantity[item.sub_item_id] == 0) {
          this.quantity[item.sub_item_id] = 1;
        }

        this.UPDATE_ITEM_ADDON({
          key: this.category,
          keyName: this.categoryName,
          id: item.sub_item_id,
          name: item.sub_item_name,
          multiple: true,
          quantity: item.quantity == 2 ? this.quantity[item.sub_item_id] : null,
          price: this.price[item.sub_item_id],
        });
      } else {
        this.quantity[item.sub_item_id] = 0;
        
        this.REMOVE_ITEM_ADDON({
          key: this.category,
          id: item.sub_item_id,
        });
      }
    },
    getQuantity(id) {
      return this.quantity[id];
    },
    getTotalQuantity() {
      return Object.values(this.quantity)?.reduce((a, c) => a + c);
    },
    getPrice(id) {
      return (Math.max(this.quantity[id], 1)) * this.price[id];
    },
    isRulesPassed() {
      if (this.rules.quantity && !eval(this.getTotalQuantity() + this.rules.quantity)) return false;
      return true;
    },
    mapSelectedById(id) {
      return this.list.reduce((r, e) => {
        r[e[id]] = null;
        return r;
      }, {});
    },
    mapQuantityById(id) {
      return this.list.reduce((r, e) => {
        r[e[id]] = 0;
        return r;
      }, {});
    },
    mapPriceById(id) {
      return this.list.reduce((r, e) => {
        r[e[id]] = e.price;
        return r;
      }, {});
    },
  },
  mounted() {
    this.quantity = this.mapQuantityById('sub_item_id', '');
    this.price = this.mapPriceById('sub_item_id', 'price');

    // Check default selected
    const selectedAddonItems = this.addonItem?.addons?.find(addon => addon.id === this.category)?.items;
    if (selectedAddonItems) {
      for (const addonItem of selectedAddonItems) {
        this.selected.push(addonItem.id);
        this.quantity[addonItem.id] = addonItem.quantity || 1;
        this.price[addonItem.id] = addonItem.price;
      }
    }
  },
};
</script>

<style scoped>
.quantxt {
  font-size: 12px;
}
.prods-innerextras > div {
  min-height: 40px !important;
}
.prods-innerextras .v-input--checkbox {
  margin-top: 0px !important;
}
.prods-innerextras .v-input__slot {
  margin-bottom: 0px !important;
}
</style>
