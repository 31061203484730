<template>
  <div>
    <v-form
      ref="form"
      method="post"
      :action="actionUrl"
    >
      <v-text-field
        type="hidden"
        name="MerchantCode"
        :value="credentials.code"
      />
      <v-text-field type="hidden" name="PaymentId" :value="paymentId" />
      <v-text-field type="hidden" name="RefNo" :value="order.order_id" />
      <v-text-field type="hidden" name="Amount" :value="(+order.total_w_tax).toFixed(2)" />
      <v-text-field type="hidden" name="Currency" value="MYR" />
      <v-text-field type="hidden" name="ProdDesc" :value="getProductDescription()" />
      <v-text-field type="hidden" name="UserName" :value="customerName" />
      <v-text-field type="hidden" name="UserEmail" :value="order.email_address" />
      <v-text-field
        type="hidden"
        name="UserContact"
        :value="order.contact_phone"
      />
      <v-text-field type="hidden" name="SignatureType" value="SHA256" />
      <v-text-field
        type="hidden"
        name="Signature"
        :value="credentials.signature"
      />
      <v-text-field type="hidden" name="ResponseURL" :value="responseUrl" />
      <v-text-field type="hidden" name="BackendURL" :value="backendUrl" />
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'iPay88',
  props: ['order', 'paymentId', 'paymentMethod'],
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
    }
  },
  computed: {
    ...mapState('customer', ['customer']),
    ...mapState('payment', {
      credentials: (state) => state.credentials,
    }),
    customerName() {
      return `${this.order.first_name} ${this.order.last_name}`;
    },
    actionUrl() {
      return location.hostname.includes('eatmol.com') ? 'https://payment.ipay88.com.my/ePayment/entry.asp' : 'https://ipay88.eatmol.com';
    },
    responseUrl() {
      return (
        process.env.VUE_APP_API_HOST +
        `/payment/${this.paymentMethod}/response?origin=` + this.getOriginUrl()
      );
    },
    backendUrl() {
      return (
        process.env.VUE_APP_API_HOST + `/payment/${this.paymentMethod}/callback`
      );
    },
  },
  methods: {
    ...mapActions('payment', ['getCredentials']),
    getOriginUrl() {
      return encodeURIComponent(window.location.href.replace('?statusCode=403', '').replace('?statusCode=500', ''));
    },
    getProductDescription() {
      return 'Payment to merchant ' + this.order.restaurant_name;
    },
  },
  async mounted() {
    await this.getCredentials({
      type: 'ip8',
      params: {
        refNo: this.order.order_id,
        amount: (+this.order.total_w_tax).toFixed(2),
        currency: 'MYR',
        method: this.paymentMethod,
      },
    });
    setTimeout(() => {
      this.$refs.form.$el.submit();
    }, 500);
  },
};
</script>

<style></style>
