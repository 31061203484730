<template>
  <div>
    <v-form
      ref="form"
      method="post"
      :action="directUrl"
    >
      <v-text-field type="hidden" name="trxId" :value="Date.now()" />
      <v-text-field type="hidden" name="paymentId" :value="paymentId" />
      <v-text-field type="hidden" name="orderId" :value="order.order_id" />
      <v-text-field type="hidden" name="amount" :value="order.total_w_tax" />
      <v-text-field type="hidden" name="currency" value="MYR" />
      <v-text-field type="hidden" name="status" value="1" />
      <v-text-field type="hidden" name="customerName" :value="customerName" />
      <v-text-field type="hidden" name="customerEmail" :value="order.email_address" />
      <v-text-field type="hidden" name="customerContact" :value="order.contact_phone" />
      <v-text-field
        type="hidden"
        name="signature"
        :value="credentials.signature"
      />
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Cash',
  props: ['order', 'paymentId', 'paymentMethod'],
  computed: {
    ...mapState('payment', {
      credentials: (state) => state.credentials,
    }),
    customerName() {
      return `${this.order.first_name} ${this.order.last_name}`;
    },
    directUrl() {
      return (
        process.env.VUE_APP_API_HOST +
        `/payment/${this.paymentMethod}/direct?origin=` + this.getOriginUrl()
      );
    },
  },
  methods: {
    ...mapActions('payment', ['getCredentials']),
    getOriginUrl() {
      return encodeURIComponent(window.location.href.replace('?statusCode=403', '').replace('?statusCode=500', ''));
    },
  },
  async mounted() {
    await this.getCredentials({
      type: this.paymentMethod,
      params: {
        paymentId: this.paymentId,
        refNo: this.order.order_id,
        amount: this.order.total_w_tax,
        currency: 'MYR',
        method: this.paymentMethod,
      },
    });

    setTimeout(() => {
      this.$refs.form.$el.submit();
    }, 500);
  },
};
</script>

<style></style>
