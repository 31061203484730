<template>
  <div class="choices mb-7" v-if="items.length > 0">
    <v-list class="py-0">
      <v-list-item 
        key="addon-category-name"
        class="font-weight-4 p-0"
      >
        <v-list-item-content class="p-0 m-0 align-self-start">
          <v-list-item-title class="fontsm font-weight-600" v-text="category.subcategory_name"></v-list-item-title>
          <v-list-item-subtitle class="text--disabled smalltxt mb-3" v-text="category.subcategory_description"></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon 
          class="p-0 pr-3 m-0"
          style="padding-top: 2px !important;"
        >
          <v-chip 
            color="rgba(69, 104, 219, 0.1)"
            :class="'mr-1 px-2 font-weight-bold chip-required ' + isQuantityRuleError()"
            x-small
            v-if="rules.quantity"
          >
            <span class="mr-0" v-if="rules.quantity">{{ getQuantityRuleLabel() }}</span>
          </v-chip>
          <v-chip 
            color="rgba(69, 104, 219, 0.1)"
            :class="'px-2 font-weight-bold chip-required ' + isCustomOptionRuleError()"
            x-small
            v-if="required"
          >
            <span class="mr-1" v-if="rules.customOption">{{ getCustomOptionRuleLabel() }}</span> Required
          </v-chip>
          <v-chip
            color="rgba(200, 200, 200, .3)"
            class="px-2 font-weight-bold chip-optional"
            x-small
            v-else
          >
            Optional
          </v-chip>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <v-container class="py-0">
      <ProductAddonSingle
        v-if="type === 'one'"
        :category="category.subcat_id"
        :categoryName="category.subcategory_name"
        :list="items"
        :required="required"
        :disabled="disabled"
      ></ProductAddonSingle>
      <ProductAddonMultiple
        v-if="type === 'multiple'"
        :category="category.subcat_id"
        :categoryName="category.subcategory_name"
        :list="items"
        :required="required"
        :rules="rules"
        :disabled="disabled"
      ></ProductAddonMultiple>
      <ProductAddonCustom
        v-if="type === 'custom'"
        :category="category.subcat_id"
        :categoryName="category.subcategory_name"
        :list="items"
        :required="required"
        :rules="rules"
        :disabled="disabled"
      ></ProductAddonCustom>
    </v-container>
  </div>
</template>

<script>
import ProductAddonCustom from '@/components/Menu/ProductAddonCustom';
import ProductAddonMultiple from '@/components/Menu/ProductAddonMultiple';
import ProductAddonSingle from '@/components/Menu/ProductAddonSingle';
import { mapState } from 'vuex';
export default {
  props: ['category', 'items', 'type', 'required', 'rules', 'disabled'],
  components: {
    ProductAddonCustom,
    ProductAddonMultiple,
    ProductAddonSingle,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('cart', {
      addonItem: (state) => state.item,
    }),
  },
  methods: {
    getTotalQuantity() {
      return this.addonItem?.addons?.find(addon => addon.id == this.category.subcat_id)?.items.reduce((a, c) => a + (c.quantity || 1), 0);
    },
    getTotalOptions() {
      return this.addonItem?.addons?.find(addon => addon.id == this.category.subcat_id)?.items.length;
    },
    getQuantityRuleLabel() {
      if (this.rules.quantity.startsWith('<=')) {
        return 'Max quantity ' + this.rules.quantity.substr(2);
      } else if (this.rules.quantity.startsWith('>=')) {
        return 'Min quantity' + this.rules.quantity.substr(2);
      } else {
        return 'Quantity ' + this.rules.quantity.substr(2) + ' only';
      }
    },
    getCustomOptionRuleLabel() {
      if (this.rules.customOption.startsWith('<=')) {
        return 'Max ' + this.rules.customOption.substr(2);
      } else if (this.rules.customOption.startsWith('>=')) {
        return 'Min ' + this.rules.customOption.substr(2);
      } else {
        return this.rules.customOption.substr(2);
      }
    },
    isQuantityRuleError() {
      return (this.addonItem.submit && !eval(this.getTotalQuantity() + this.rules.quantity)) ? 'error' : '';
    },
    isCustomOptionRuleError() {
      return this.addonItem.submit && !eval(this.getTotalOptions() + this.rules.customOption) ? 'error' : '';
    },
  },
};
</script>

<style scoped>
/*extrasquanbtns{*/
/*  margin-left: 1px;*/
/*}*/
.choices .theme--light.v-label {
  color: black !important;
  font-weight: 600;
}
.extrasquanbtns,
.extrasquanbtns .v-icon.v-icon {
  font-size: 20px !important;
}

.smalltxt {
  font-size: 10px !important;
}

.v-list-item {
  min-height: 20px;
}

.chip-required {
  font-size: 9px;
  color: rgb(69, 104, 219);
}

.chip-required.error {
  font-size: 9px;
  color: rgb(219, 104, 69);
  background-color: rgba(219, 104, 69, .1) !important;
}

.chip-optional {
  font-size: 9px;
  color: #777777;
}

@media screen and (max-width: 414px) {
  .extrasquanbtns,
  .extrasquanbtns .v-icon.v-icon {
    font-size: 16px !important;
  }
  .quantxt {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 1024px) {
  .extrasquanbtns,
  .extrasquanbtns .v-icon.v-icon {
    font-size: 16px !important;
  }
  .quantxt {
    font-size: 12px !important;
  }
}
</style>
