<template>
  <v-row justify="center">
    <v-dialog
      v-model="showVoucher"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-app-bar outlined short color="toolbar" elevation="0.5">
        <v-spacer></v-spacer>
        <v-toolbar-title
          align-self="center"
          class="toolbarText--text text-button font-weight-6 p-0 m-0 ml-2"
        >
          Available Vouchers
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon class="toolbarText--text ml-2" @click="close()">mdi-close</v-icon>

        <template v-slot:extension>
          <v-text-field
            v-model.trim="code"
            color="grey lighten-1"
            class="search-voucher-code text-caption bg-white mb-4"
            placeholder="Enter voucher code here"
            rounded
            dense
            filled
            hide-details
            autocomplete="off"
          ></v-text-field>
        </template>
      </v-app-bar>
      <v-sheet
        height="calc(100vh - 104px)"
        color="listBackground"
        style="position: relative; z-index: 0"
        class="overflow-y-auto p-0"
      >
        <v-card flat v-if="availableVouchers.length == 0">
          <v-list color="#ffffff">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  style="font-size: 12px !important"
                  class="text-caption font-weight-bold mt-2"
                >
                  No voucher at the moment
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <v-card
          flat
          v-for="voucher in availableVouchers"
          :key="voucher.id"
          class="m-3 p-0"
        >
          <v-row class="mx-0">
            <v-col
              class="voucher-owner align-content-center p-0"
              :class="{ 'voucher-product': isProductVoucher(voucher) }"
              cols="3"
            >
              <v-row class="mx-0">
                <v-col class="text-center p-0">
                  <v-img
                    :src="getProductPhoto(voucher)"
                    height="150"
                    cover
                    v-if="isProductVoucher(voucher)"
                  ></v-img>
                  <v-icon large color="primary" v-else>mdi-ticket-percent</v-icon>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="align-content-center pl-3 p-1" cols="7">
              <div
                class="
                  text-body-2 text-wrap text-uppercase
                  primary--text
                  font-weight-bold
                "
              >
                {{ voucher.code }}
                <v-chip
                  small
                  class="text-caption text-capitalize mr-1 limited-offer"
                  style="margin-top:-2px;"
                  v-if="voucher.visibility"
                  >Private</v-chip
                >
                <v-chip
                  small
                  class="text-caption text-lowercase mr-1 mb-1 limited-quantity"
                  v-if="voucher.claimed > 1"
                  >x{{ voucher.claimed }}</v-chip
                >
              </div>
              <div class="d-flex flex-column">
                <small
                  class="voucher-criteria font-weight-6 green--text text--darken-1 mt-1"
                >
                  {{
                    voucher.type === 'percentage'
                      ? voucher.amount + '%'
                      : $n(voucher.amount, 'currency', 'en-MY')
                  }}
                  off
                </small>
                <small
                  class="voucher-criteria font-weight-5 green--text text--darken-1"
                >
                  Min. purchase
                  <span class="font-weight-6">{{
                    $n(voucher.min_purchase, 'currency', 'en-MY').split('.')[0]
                  }}</span>
                </small>
                <small
                  class="voucher-criteria font-weight-5 mt-2"
                  v-if="isProductVoucher(voucher)"
                >
                  * For <span class="font-weight-bold text-wrap">{{ voucher.item.name }}</span> only
                </small>
              </div>
              <div class="voucher-service text-wrap mt-1">
                <v-chip
                  small
                  class="text-caption mr-1 mt-1 limited-offer"
                  v-if="voucher.delivery"
                  >Delivery</v-chip
                >
                <v-chip
                  small
                  class="text-caption mr-1 mt-1 limited-offer"
                  v-if="voucher.pickup"
                  >Pickup</v-chip
                >
                <v-chip
                  small
                  class="text-caption mr-1 mt-1 limited-offer"
                  v-if="voucher.dinein"
                  >Dine In</v-chip
                >
              </div>
              <small class="voucher-criteria" v-if="canUse(voucher.startAt)">
                Valid until
                <b>{{ formatDate(voucher.expiration) }}</b></small
              >
              <small class="voucher-criteria" v-else>
                <v-icon x-small dense style="margin-top:-3px;">mdi-timer-sand</v-icon> Use from: 
                <b>{{ formatDate(voucher.startAt) }}</b></small
              >
            </v-col>
            <v-col
              class="p-0 pr-2"
              :class="{ 'align-content-center': !voucher.fully_redeemed }"
              cols="2"
            >
              <div class="text-center">
                <div
                  class="text-overline font-weight-bold"
                  v-if="voucher.fully_redeemed"
                >
                  <div class="stamp p-2">
                    Fully Redeemed
                  </div>
                </div>
                <div
                  style="cursor: pointer"
                  class="font-weight-bold"
                  v-else-if="voucher.claimed > 0"
                >
                  <v-btn
                    x-small
                    outlined
                    ripple
                    color="primary"
                    class="btn-cta-use px-3 py-1"
                    v-if="canUse(voucher.startAt)"
                    @click.stop="apply(voucher)"
                    >Use</v-btn
                  >
                  <v-btn
                    x-small
                    outlined
                    ripple
                    color="disabled"
                    class="btn-cta-use px-3 py-3"
                    v-else
                  >
                    Use<br/>Later
                  </v-btn>
                </div>
                <div
                  style="cursor: pointer"
                  class="font-weight-bold"
                  v-else
                >
                  <v-btn
                    x-small
                    ripple
                    color="primary"
                    class="btn-cta-use font-weight-6 px-3 py-3"
                    @click.stop="claim(voucher)"
                  >
                    Claim
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-sheet>
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'VoucherModal',
  props: ['visible', 'selected'],
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      defaultVouchers: [],
      code: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
      vouchers: (state) => state.vouchers,
    }),
    ...mapState('order', {
      orders: (state) => state.list,
    }),
    ...mapGetters('auth', {
      isLoggedIn: 'isLoggedIn',
    }),
    availableVouchers: function () {
      return (this.defaultVouchers || []).filter(v => !this.code || v.code.toLowerCase().includes(this.code.toLowerCase()) || this.code.toLowerCase().includes(v.code.toLowerCase()));
    },
    showVoucher: {
      get: function () {
        return this.visible;
      },
      set: function (val) {
        if (!val) {
          this.$emit('close-voucher', false);
        }
      },
    },
  },
  methods: {
    ...mapActions('customer', ['claimVoucher']),
    ...mapActions('merchant', ['getMerchantVouchers']),
    ...mapActions('order', ['getOrders']),
    formatDate(date) {
      return dayjs(date).format('DD MMM YYYY');
    },
    canUse(startAt) {
      return !startAt || !dayjs().isBefore(dayjs(startAt), 'day');
    },
    isProductVoucher(voucher) {
      return voucher.type === 'product';
    },
    getProductPhoto(voucher) {
      return voucher?.item?.photo_s3
        ? `${this.cdnUrl}/${voucher?.item?.photo_s3}`
        : `${this.legacyCdnUrl}/upload/${voucher?.item?.photo}`;
    },
		async claim(voucher) {
			try {
				this.isLoading = true;

				this.$gtag.event('claim-voucher', {
					event_category: 'engagement',
					event_label: 'Claim Voucher',
					event_value: voucher.id,
				});

				await this.claimVoucher({
					voucherId: voucher.id,
					merchantId: this.profile.merchant_id,
				});

				this.defaultVouchers = await this.getMerchantVouchers({
					merchantId: this.profile.merchant_id,
				});

				this.isLoading = false;
			} catch(e) {
				this.isLoading = false;
				alert(e.message);
			}
		},
    apply(voucher) {
      this.$emit('apply-voucher', voucher);
      this.close();
    },
    close() {
      this.showVoucher = false;
    },
  },
  async mounted() {
    this.defaultVouchers = await this.getMerchantVouchers({
      merchantId: this.profile.merchant_id,
    });
    await this.getOrders();
  },
  watch: {
    selected: async function (val) {
      this.code = val;
    },
  },
};
</script>
<style scoped>
.limited-offer {
  font-size: 8px !important;
  font-weight: 600;
  height: 18px;
  padding: 0 8px;
  border-radius: 4px;
  color: #039be5 !important;
  background-color: rgba(3, 155, 229, 0.1) !important;
}

.limited-quantity {
  font-size: 10px !important;
  font-weight: bold;
  height: 20px;
  border-radius: 4px;
  color: #ff6347 !important;
  background-color: rgba(255, 99, 71, 0.1) !important;
}

.v-list-item__action-text {
  color: #ff6347 !important;
}

.stamp {
	position: absolute;
	top: 50%;
	right: 8px;
  transform: translateY(-50%) rotate(-15deg);
	color: #ff6347;
  line-height: 0.75rem;
	font-size: 0.4rem;
	font-weight: 700;
	border: 0.25rem solid #ff6347;
	text-transform: uppercase;
	border-radius: 1rem;
	mask-image: url('../../assets/images/grunge.webp');
	-webkit-mask-image: url('../../assets/images/grunge.webp');
  mix-blend-mode: multiply;
}

.voucher-owner {
  min-height: 110px;
  font-size: 10px;
  border: 0.55px solid var(--v-primary);
}

.voucher-owner.voucher-product {
  height: 150px;
}

.voucher-owner .restaurant-name {
  font-size: 8px;
}

.voucher-criteria {
  font-size: 8px;
  color: rgba(0, 0, 0, 0.6);
}

.voucher-service {
  margin-left: -3px;
}

.btn-cta-use {
  width: 45px;
  font-size: 7px;
}
</style>