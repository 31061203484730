<template>
  <v-container class="p-0">
    <v-radio-group 
      v-model="selected" 
      :disabled="disabled" 
      :error="required && item.submit && !selected"
      @change="onSelected"
      class="mt-0"
      hide-details
    >
      <v-row
        align="center"
        class="prods-innerextras"
        v-for="(item, i) in list"
        :key="i"
      >
        <v-col cols="8" class="p-0">
          <v-radio
            class="smalltxt"
            :label="item.sub_item_name"
            :value="item"
          ></v-radio>
          <small
            class="text--disabled ml-8 smalltxt"
            style="position: relative; top: -8px;"
            v-if="item.item_description"
            >{{ item.item_description }}</small
          >
        </v-col>
        <v-col cols="4" class="smalltxt pl-0 font-weight-6 text-right">
          +{{ $n(price[item.sub_item_id], 'currency', 'en-MY') }}
        </v-col>
      </v-row>
    </v-radio-group>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'ProductAddonSingle',
  props: ['category', 'categoryName', 'list', 'required', 'disabled'],
  data() {
    return {
      price: {},
      selected: null,
    };
  },
  computed: {
    ...mapState('cart', {
      item: (state) => state.item,
    }),
  },
  methods: {
    ...mapMutations('cart', ['UPDATE_ITEM_ADDON']),
    prettyPrice(price) {
      return parseFloat(price).toFixed(2);
    },
    onSelected() {
      this.UPDATE_ITEM_ADDON({
        key: this.category,
        keyName: this.categoryName,
        id: this.selected.sub_item_id,
        name: this.selected.sub_item_name,
        multiple: false,
        price: this.price[this.selected.sub_item_id],
      });
    },
  },
  mounted() {
    this.price = this.list.reduce((r, e) => {
      r[e.sub_item_id] = e.price ? e.price : 0.0;
      return r;
    }, {});

    // Check default selected
    const selectedAddonItemId = this.item?.addons?.find(addon => addon.id === this.category)?.items[0].id;
    if (selectedAddonItemId) {
      this.selected = this.list.find(addonItem => addonItem.sub_item_id === selectedAddonItemId);
    }
  },
};
</script>

<style scoped>
.quantxt {
  font-size: 12px !important;
}

@media screen and (max-width: 414px) {
  .prods-innerextras .smalltxt,
  .prods-innerextras .v-label {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 1024px) {
  .quantxt {
    font-size: 12px;
  }
  .prods-innerextras .smalltxt,
  .v-label {
    font-size: 12px !important;
  }
}
</style>
