<template>
  <v-dialog persistent v-model="show" transition="dialog-bottom-transition">
    <v-card>
      <v-card-title class="text-overline font-weight-6 primary--text justify-center">Your Cart Updated!</v-card-title>
      <v-card-text class="text-caption pb-3" v-text="getMessage()"></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="font-weight-6"
          small
          text
          plain
          @click="close()"
        >
          Got it!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  props: ['type'],
  data() {
    return {
      show: true,
    }
  },
  methods: {
    getMessage() {
      if (this.type == 'price') {
        return `Your cart total is updated. Please check before confirm your order.`;
      } else if (this.type == 'item') {
        return `Some item(s) in your cart are not available at the moment. Sorry for any inconvenience caused.`;
      } else {
        return `Some item(s) in your cart are not available or price changed. Please check before confirm your order.`;
      }
    },
    close() {
      this.$emit('close-notify-cart-changed', this.type);
      this.show = false;
    }
  },
};
</script>

<style scoped>
</style>
